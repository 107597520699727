import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ProtectedRoute from './component/ProtectedRoute';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import store from './store';

const Login = React.lazy(() => import('./Pages/Login'));

const FallBack = () => <div className="d-flex justify-content-center">Načítám...</div>;

export default function App() {
  
  const persistor = persistStore(store);

  return (
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <React.Suspense fallback={<FallBack />}>
            <Switch>
              <Route exact={true} path="/login" name="Login page" render={props => <Login {...props} />} />
              <ProtectedRoute path="/" name="Home" />
            </Switch>
          </React.Suspense>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  );
}