import axios from 'axios';

//const api_url = "http://192.168.17.210:8001";
//const api_url = "http://server.hanak-nabytek.cz:8001";
const api_url = "https://api.dochazka.wellness-kromeriz.cz";

const Axios = axios.create({
    baseURL: api_url,
    timeout: 5000
});

export default Axios;
