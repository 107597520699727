import { ATTENDANCE_DETAIL } from '../types';
import update from 'immutability-helper';

const def_state = {
    //data pro vygenerovani dochazky
    attendance_detail: {},
};

export default function attendance(state = def_state, action){
    switch(action.type){
        case ATTENDANCE_DETAIL:
            return update(state, {attendance_detail: {$set: action.payload}});
        default:
            return state;
    }
}