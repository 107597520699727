export const LOGOUT_USER = "LOGOUT_USER";
export const LOGIN_STATUS = "LOGIN_STATUS";

/* USER */
export const USER_DETAIL = "USER_DETAIL";
export const USER_LOGOUT = "USER_LOGOUT";
export const LAST_ACTIVITY = "LAST_ACTIVITY";

/* ATTENDANCE */
export const ATTENDANCE_DETAIL = "ATTENDANCE_DETAIL";
export const ATTENDANCE_USER = "ATTENDANCE_USER";