import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

import { LOGOUT_USER } from '../types';

import attendance from './attendance';
import user from './user';
import login from './login';

const reducer = combineReducers({
    attendance: attendance,
    user: user,
    login: login,
});

const rootReducer = (state, action) => {
    if(action.type === LOGOUT_USER){
        //smazeme persistentni uloziste
        storage.removeItem('persist:main');
        //vymazeme redux
        state = undefined;
    }
    return reducer(state, action);
};

export default rootReducer;
