import { USER_DETAIL, USER_LOGOUT,  } from '../types';
import update from 'immutability-helper';

const def_state = {
    logged_in: false,
    detail: {},
};

export default function user(state = def_state, action){
    switch(action.type){
        case USER_DETAIL:
            return update(state, {detail: {$set: action.payload}, logged_in: {$set: true}});
        case USER_LOGOUT:
            return update(state, {defailt: {$set: {}, logged_in: {$set: false}}});
        default:
            return state;
    }
}