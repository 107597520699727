import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { logoutUserTimeout } from '../Redux/Actions/login';
import Cookie from 'js-cookie';
//import { redirectuUrl } from '../Redux/Actions/Login';

const DefaultLayout = React.lazy(() => import('../containers/DefaultLayout'));

export default function ProtectedRoute({ path, history, match, name}){

    const dispatch = useDispatch();
    const logged_in = useSelector(state => state.user.logged_in);

    useEffect(() => {
        if(typeof Cookie.get('last_action') === typeof undefined) dispatch(logoutUserTimeout());
        else{
            const expire_time = new Date(new Date().getTime() + 61 * 60 * 1000);
            Cookie.set('last_action', new Date(), { expires: expire_time});
        }
    }, [ ]);

    return(
        (logged_in === true) ? <Route path={path} name={name} render={props => <DefaultLayout {...props}/> } />
            : <Route render={() => <Redirect to="/login" />} />
    );

};