
import { USER_DETAIL, USER_LOGOUT } from '../types';

export const logoutUserFull = () => (
    {
        type: USER_LOGOUT,
    }
);

export const setUserDetail = detail => (
    {
        type: USER_DETAIL,
        payload: detail
    }
);