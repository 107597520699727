import { LOGOUT_USER, LOGIN_STATUS } from '../types';
import { setUserDetail } from './user';
import Axios from '../../_axios_noheaders';

export const loginUser = (login, passwd) => {
    return(dispatch) => {
        dispatch(setLoginStatus("Přihlašování..."));
        Axios.get("/login", {headers : {login: login, passwd: passwd}}).then(res => {
            dispatch(setUserDetail(res.data));
        }).catch(res => {
            let output = "Ups, něco se pokazilo";
            if(typeof res.response.data !== typeof undefined) output = res.response.data.message;
            dispatch(setLoginStatus(output));
        });
    }
};

//násilně odhlásíme uživatele kvůli timeoutu
export const logoutUserTimeout = () => {
    return(dispatch) => {
        //vymazeme info uzivatele
        dispatch(logoutUser({}));
        //nastavime hlasku pro login stranku
        dispatch(setLoginStatus("Proběhlo odhlášení z důvodu nečinnosti"));
    }
};

const setLoginStatus = status => (
    {
        type: LOGIN_STATUS,
        payload: status
    }
);

export const logoutUser = () => (
    { type: LOGOUT_USER }
);