import { LOGIN_STATUS } from '../types';
import update from 'immutability-helper';

const def_state = {
    login_status: "",
};

export default function user(state = def_state, action){
    switch(action.type){
        case LOGIN_STATUS:
            return update(state, {login_status: {$set: action.payload}});
        default:
            return state;
    }
}