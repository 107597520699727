import { logoutUserTimeout } from './Redux/Actions/login';
import { createStore, applyMiddleware, compose } from 'redux';
import ReduxThunk from 'redux-thunk';
import rootReducer from './Redux/Reducers/';
import { persistReducer } from 'redux-persist';
import logger from 'redux-logger';
import storage from 'redux-persist/lib/storage';
import createEncryptor from 'redux-persist-transform-encrypt';
import Cookie from 'js-cookie';
//import { persistReducer } from 'redux-persist';

const unloger = store => {
    let timeout_ref = null;
    return next => action => {
        if(timeout_ref !== null && typeof timeout_ref !== typeof undefined) clearTimeout(timeout_ref);
        //zavola next, tudiz v store.getState() mame jiz novy state
        const result = next(action);
        const expire_time = new Date(new Date().getTime() + 61 * 60 * 1000);
        if(action.type.includes("persist") === false) Cookie.set('last_action', new Date(), { expires: expire_time});
        if(store.getState().user.logged_in === true ){
            timeout_ref = window.setTimeout(() => {
                store.dispatch(logoutUserTimeout());
            }, 3600000 /* hodina */);
        }
        return result;
    }
};

const encryptor = createEncryptor({
    secretKey: 'djfl))ksd%jwerěšěščěščsdWER234df54š!!"f',
    onError: error => {
        console.error("redux encryptor failed");
        console.error(error);
    }
});

const persistConfig = {
    key: 'dochazka',
    transforms: [ encryptor ],
    storage,
    blacklist: [
        "attendance",
        "login",
    ]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
//const persistedReducer = rootReducer;

const store = createStore(persistedReducer, {}, compose(applyMiddleware(ReduxThunk, logger, unloger)));

export default store;